
.wysiwyg-editor blockquote,
.wysiwyg-editor p{
    margin-top: 1em;
    margin-bottom: 1em;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1.15em;
    line-height: 1.25;
    font-weight: 300;
    font-style: normal;
}

.wysiwyg-editor h2,
.wysiwyg-editor h3,
.wysiwyg-editor h4,
.wysiwyg-editor h5,
.wysiwyg-editor h6,
.wysiwyg-editor p{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.wysiwyg-editor h2{
    font-size: 2em;
    line-height: 1.15384615;
    margin-top: 1.15384616em;
    margin-bottom: .38461539em;
}

.wysiwyg-editor h3{
    font-size: 1.75em;
    line-height: 1.13636364;
    margin-top: 1.36363637em;
    margin-bottom: .45454546em;
}

.wysiwyg-editor h4{
    font-size: 1.15em;
    line-height: 1.11111111;
    margin-top: 1.66666667em;
    margin-bottom: .55555556em;
}

.wysiwyg-editor a {
    line-height: 1.5;
    letter-spacing: 0;
    color: #70C1B3;
    -webkit-text-decoration: none;
    text-decoration: none;
}