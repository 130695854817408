.masonry-grid {
    display       : -webkit-box;
    /* Not needed if autoprefixing */
    display       : -ms-flexbox;
    /* Not needed if autoprefixing */
    display       : flex;
    flex-direction: row;
    width         : auto;
}

.masonry-grid_column {
    margin-right: 10px;
}

.masonry-grid_column:last-child {
    margin-right: 0;
}

.masonry-grid_column>div {
    display      : flex;
    margin-bottom: 10px;
}

.masonry-grid_column>div:last-child {
    margin-bottom: 0;
}